const SIDEBAR = {
    OPEN: 'golosmarket/sidebar/open',
    CLOSE: 'golosmarket/sidebar/close',
    TOGGLE: 'golosmarket/sidebar/toggle'
};


export const openSidebar = () => ({ type: SIDEBAR.OPEN });
export const closeSidebar = () => ({ type: SIDEBAR.CLOSE });
export const toggleSidebar = () => ({ type: SIDEBAR.TOGGLE });

const initialState = { open: true };

export const sidebar = (state = initialState, action) => {
    switch (action.type) {
        case SIDEBAR.OPEN:
            return { ...state, open: true };

        case SIDEBAR.CLOSE:
            return { ...state, open: false };

        case SIDEBAR.TOGGLE:
            return { ...state, open: !state.open };

        default:
            return state;
    }
};
