import {getJsonData} from "../../helpers/Tools";
import {StorageService} from "../../services";
import {STORAGE_KEY} from "../../constants/LocalStorageKeys";
import ANNOUNCER from "../../api/apiList";

const FAVORITE = {
  UPDATE: 'golosmarket/localFavorite/UPDATE'
};

export const favorites = getJsonData(StorageService.get(STORAGE_KEY.FAVORITES) || '[]');

const initialState = {
  favList: favorites
};

export const favLocalList = (state = initialState, action) => {
  switch (action.type) {
    case ANNOUNCER.FAVORITE:
      console.log('ANNOUNCER.FAVORITE', action, state);

      break;
    case FAVORITE.UPDATE:
      StorageService.set(STORAGE_KEY.FAVORITES, JSON.stringify(action.payload.favList));
      return {
        ...state,
        favList: action.payload.favList
      };

    default:
      return state;
  }
};

export const setFavLocalList = (favList) => {
  return dispatch => {
    dispatch({
      type: FAVORITE.UPDATE,
      payload: {
        favList
      }
    });
  }
};
