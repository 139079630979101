import { stopSubmit, startSubmit } from "redux-form";
import { ReviewService } from "../../services";
import { FORMS } from "../../constants";
import { convertErrors } from "../../helpers/RequestHelpers";

const REVIEW = {
    LOADING: 'golosmarket/review/SEND',
    LOAD: 'golosmarket/review/LOADED',
    FAIL: 'golosmarket/review/FAIL',
};
const FEEDBACK = {
    LOADING: 'golosmarket/feedback/SEND',
    LOAD: 'golosmarket/feedback/LOADED',
    FAIL: 'golosmarket/feedback/FAIL',
};
const REVIEW_CREATE = {
    LOADING: 'golosmarket/REVIEW_CREATE/SEND',
    LOAD: 'golosmarket/REVIEW_CREATE/LOADED',
    FAIL: 'golosmarket/REVIEW_CREATE/FAIL',
};
const USER_REVIEW_CREATE = {
    LOADING: 'golosmarket/USER_REVIEW_CREATE/SEND',
    LOAD: 'golosmarket/USER_REVIEW_CREATE/LOADED',
    FAIL: 'golosmarket/USER_REVIEW_CREATE/FAIL',
};

export function requestReviews(filter) {
    return dispatch => {
        dispatch({
            type: REVIEW.LOADING,
            payload: { isLoading: true }
        });
        return ReviewService
            .get(filter)
            .then(response => response.data)
            .then(data => {
                dispatch({
                    type: REVIEW.LOAD,
                    payload: {
                        items: data,
                        pagination: {
                            perPage: +data.per_page,
                            page: +data.current_page,
                            isNextPage: !!data.next_page_url
                        },
                        isLoading: false
                    }
                });
            });
    }
}

export function CreateFeedback(params) {
    return dispatch => {
        dispatch({
            type: FEEDBACK.LOADING,
            payload: { isLoading: true }
        });
        return ReviewService
            .createFeedback(params)
            .then(response => response.data)
            .then(data => {
                dispatch({
                    type: FEEDBACK.LOADED,
                    payload: { isLoading: false }
                });
            })
            .catch(data => {
                const fields = convertErrors(data);
                const action = stopSubmit(FORMS.FEEDBACK, fields);
                dispatch(action);

                return dispatch({
                    type: FEEDBACK.FAIL,
                    payload: { ...data,  isLoading: false  }
                });
            });
    };
}

export function CreateReviewService(params) {
    return dispatch => {
        dispatch({
            type: REVIEW_CREATE.LOADING
        });
        const action = startSubmit(FORMS.REVIEW_SERVICE);
        dispatch(action);
        return ReviewService
            .createReviewService(params)
            .then(response => response.data)
            .then(data => {
                dispatch({
                    type: REVIEW_CREATE.LOADED,
                    payload: data
                });
            })
            .catch(data => {
                const fields = convertErrors(data);
                const action = stopSubmit(FORMS.REVIEW_SERVICE, fields);
                dispatch(action);

                return dispatch({
                    type: REVIEW_CREATE.FAIL,
                    payload: { ...data }
                });
            });
    };
}

export function CreateUserReview(params) {
    return dispatch => {
        dispatch({
            type: USER_REVIEW_CREATE.LOADING
        });
        return ReviewService
            .createReviewUser(params)
            .then(response => response.data)
            .then(data => {
                dispatch({
                    type: USER_REVIEW_CREATE.LOADED,
                    payload: data
                });
            })
            .catch(data => {
                const fields = convertErrors(data);
                const action = stopSubmit(FORMS.REVIEW_USER, fields);
                dispatch(action);

                return dispatch({
                    type: USER_REVIEW_CREATE.FAIL,
                    payload: { ...data }
                });
            });
    };
}

const initialState = {
    isLoading: false,
    reviews: window.reviews ? window.reviews : [],
    message: '',
    pagination: {
        perPage: 0,
        page: 1,
        isNextPage: false
    },
};

export const review = (state = initialState, { type, payload }) => {
    switch (type) {
        case REVIEW.LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case REVIEW.LOAD:
            return {
                ...initialState,
                reviews: payload.items,
                pagination: payload.pagination,
            };

        case REVIEW.FAIL:
            return {
                ...state,
                isLoading: false,
                ...payload,
            };

        default:
            return state;
    }
};

export const feedback = (state = {isLoading: false}, { type, payload }) => {
    switch (type) {
        case FEEDBACK.LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case FEEDBACK.LOAD:
            return {
                ...initialState,
                feedback: payload,
            };

        case FEEDBACK.FAIL:
            return {
                ...state,
                isLoading: false,
                ...payload,
            };

        default:
            return state;
    }
};
