import API from '../api/api';
import {AuthService, ProfileService} from "../services";
import {getProfile} from "./user";
import {setToken, Refresh} from "./auth";
import config from '../config/';

const INIT_SET = 'APP_INIT_SET';
const SET_MOBILE = 'APP_SET_MOBILE';
export const APP_UPDATE_EVENT = 'APP_UPDATE_EVENT';

export const initSuccess = () => ({type: INIT_SET});

export const initializeApp = () => dispatch => {
  const services = [];
  try {
    const token = AuthService.getToken();
    if (token === '') {
      const tokenRefresh = AuthService.getTokenRefresh();
      if (tokenRefresh !== '' && tokenRefresh.length) {
        services.push(Refresh({refresh_token: tokenRefresh, dispatch}));
      }
    } else if (token) {
      services.push(dispatch(setToken(token)));
      API.setToken(token);
      services.push(getProfile());
    }
  } catch (e) {
    services.push(Promise.reject());
  }
  if (services.length === 0) {
    dispatch(initSuccess())
  }
  setInterval(() => document.dispatchEvent(new CustomEvent(APP_UPDATE_EVENT)), 5000);
  return Promise.all(services)
    .then(() => dispatch(initSuccess()))
    .catch(() => dispatch(initSuccess()));
};

const initialState = {
  initialized: false,
  mobile: window.innerWidth < 543,
  localDev: process.env.NODE_ENV !== 'production',
  apiUrl: config.apiURL,
  url: config.URL,
};

window.srvr = initialState.localDev ? initialState.url : '';

export const app = (state = initialState, action) => {
  switch (action.type) {
    case INIT_SET:
      return {
        ...state,
        initialized: true
      };
    case SET_MOBILE:
      return {
        ...state,
        mobile: action.payload.mobile
      };

    default:
      return state;
  }
};

export const setIsMobile = (mobile) => {
  return dispatch => {
    dispatch({
      type: SET_MOBILE,
      payload: {
        mobile
      }
    });
  }
};
