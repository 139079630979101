import axios from 'axios';

import API from '../api/api';
import ApiList from '../api/apiList';
import { ParseToRequest } from '../helpers/Tools';

const CancelToken = axios.CancelToken;

let source;

export const LocationCityService = {
    get: (form, id = '') => {
        source = CancelToken.source();
        return API.get(`${ ApiList.LOCATION.CITY }${ id ? `/${ id }` : '' }${ ParseToRequest(form) }`, {
            cancelToken: source.token
        });
    },
    delete: (id) => API.delete(`${ ApiList.LOCATION.CITY }/${ id }`),
    create: (form) => API.post(ApiList.LOCATION.CITY, form),
    update: (form, id) => API.put(`${ ApiList.LOCATION.CITY }/${ id }`, form),
    cancelLast: () => source && source.cancel('Operation canceled by the user.')
};

export const RegionService = {
    get: (form, id = '') => {
        source = CancelToken.source();
        return API.get(`${ ApiList.LOCATION.REGION }${ id ? `/${ id }` : '' }${ ParseToRequest(form) }`, {
            cancelToken: source.token
        });
    },
    delete: (id) => API.delete(`${ ApiList.LOCATION.REGION }/${ id }`),
    create: (form) => API.post(ApiList.LOCATION.REGION, form),
    update: (form, id) => API.put(`${ ApiList.LOCATION.REGION }/${ id }`, form),
    cancelLast: () => source && source.cancel('Operation canceled by the user.')
};

export const DistrictService = {
    get: (form, id = '') => {
        source = CancelToken.source();
        return API.get(`${ ApiList.LOCATION.DISTRICT }${ id ? `/${ id }` : '' }${ ParseToRequest(form) }`, {
            cancelToken: source.token
        });
    },
    delete: (id) => API.delete(`${ ApiList.LOCATION.DISTRICT }/${ id }`),
    create: (form) => API.post(ApiList.LOCATION.DISTRICT, form),
    update: (form, id) => API.put(`${ ApiList.LOCATION.DISTRICT }/${ id }`, form),
    cancelLast: () => source && source.cancel('Operation canceled by the user.')
};

export const CountryService = {
    get: (form, id = '') => {
        source = CancelToken.source();
        return API.get(`${ ApiList.LOCATION.COUNTRY }${ id ? `/${ id }` : '' }${ ParseToRequest(form) }`, {
            cancelToken: source.token
        });
    },
    delete: (id) => API.delete(`${ ApiList.LOCATION.COUNTRY }/${ id }`),
    create: (form) => API.post(ApiList.LOCATION.COUNTRY, form),
    update: (form, id) => API.put(`${ ApiList.LOCATION.COUNTRY }/${ id }`, form),
    cancelLast: () => source && source.cancel('Operation canceled by the user.')
};
