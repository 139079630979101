import { ProfileService } from "../../services";
import {ANNOUNCER} from "../announcer";
import {toast} from "react-toastify";

const PROFILE = {
    IS_LOADING: 'golosmarket/user/profile/full/IS_LOADING',
    SEND: 'golosmarket/user/profile/full/SEND',
    LOADED: 'golosmarket/user/profile/full/LOADED',
    USER_LOADED: 'golosmarket/user/profile/full/USER_LOADED',
    USER_UPDATE: 'golosmarket/user/profile/full/USER_UPDATE',
    FAIL: 'golosmarket/user/profile/full/FAIL',
    USER_FAIL: 'golosmarket/user/profile/full/USER_FAIL',
    CREATE_SEND: 'golosmarket/user/profile/full/create/SEND',
    CREATE_LOADED: 'golosmarket/user/profile/full/create/LOADED',
    CREATE_FAIL: 'golosmarket/user/profile/full/create/FAIL',
    REMOVE_FILE_SEND: 'golosmarket/user/profile/full/remove/file/SEND',
    REMOVE_FILE_LOADED: 'golosmarket/user/profile/full/remove/file/LOADED',
    REMOVE_FILE_FAIL: 'golosmarket/user/profile/full/remove/file/FAIL',
    DEFAULT_SOUND_SEND: 'golosmarket/user/profile/full/default/sound/SEND',
    DEFAULT_SOUND_LOADED: 'golosmarket/user/profile/full/default/sound/LOADED',
    DEFAULT_SOUND_FAIL: 'golosmarket/user/profile/full/default/sound/FAIL',
};

export function getProfileFull() {
    return dispatch => {
        dispatch({
            type: PROFILE.SEND
        });
        return ProfileService
            .getFull()
            .then(response => response.data)
            .then(({ options, profile, notifications }) => dispatch({
                type: PROFILE.LOADED,
                payload: {
                    profile,
                    options,
                    notifications,
                }
            }))
            .catch(() => dispatch({
                type: PROFILE.FAIL,
            }));
    }
}

export function getProfileId(id) {
    return dispatch => {
        return ProfileService
            .getFullId(id)
            .then(response => response.data)
            .then(({ options, profile }) => {
                dispatch({
                type: PROFILE.USER_LOADED,
                payload: {
                    userProfile: profile,
                }
            })})
            .catch((e) => {
                console.log('USER_FAIL', id, e);

                dispatch({
                    type: PROFILE.USER_FAIL,
                })
            });
    }
}

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${ parentKey }[${ key }]` : key);
        });
    } else if (data instanceof FileList) {
        for (let i = 0; i < data.length; i++) {
            const file = data[i];
            console.log('FileList', data, parentKey, i, file);
            formData.append(`${ parentKey }[${ i }]`, file);
        }
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}

export function updateProfile(data) {
    return dispatch => {
        dispatch({
            type: PROFILE.IS_LOADING,
            payload: true
        });

        const formData = new FormData();
        formData.enctype = 'multipart/form-data';

        buildFormData(formData, data);

        return ProfileService
            .update(formData)
            .then(response => response.data)
            .then(profile => {
                profile.update_time = new Date();
                dispatch({
                    type: PROFILE.CREATE_LOADED,
                    payload: {
                        profile
                    }
                });
            })
            .catch((answer) => {
                if (answer.status === 413) {
                    toast.error('Размер файла не должен превышать 16мб');
                }
            })
            .finally(() => {
                dispatch({
                    type: PROFILE.IS_LOADING,
                    payload: false
                });
            });
    }
}

export function removeFile(data) {
    return dispatch => {
        dispatch({
            type: PROFILE.REMOVE_FILE_SEND,
        });

        const formData = new FormData();
        formData.enctype = 'multipart/form-data';

        buildFormData(formData, data);

        return ProfileService
            .removeFile(formData)
            .then(() => {
                dispatch({
                    type: PROFILE.REMOVE_FILE_LOADED,
                });
            });
    }
}

export function soundDefault(data) {
    return dispatch => {
        dispatch({
            type: PROFILE.DEFAULT_SOUND_SEND,
        });

        const formData = new FormData();
        formData.enctype = 'multipart/form-data';

        buildFormData(formData, data);

        return ProfileService
            .defaultSound(formData)
            .then(() => {
                dispatch({
                    type: PROFILE.DEFAULT_SOUND_LOADED,
                });
            });
    }
}

const initialState = {
    isLoading: false,
    profile: {},
    userProfile: {},
    options: {
        optionGenders: [],
        optionAges: [],
        optionTimbers: [],
        optionPresentations: [],
        optionLanguages: [],
        optionTags: [],
    },
    notifications: {
    }
};

export const profile = (state = initialState, { payload, type }) => {
    switch (type) {
        case PROFILE.IS_LOADING:
            return {
                ...state,
                isLoading: payload
            };

        case PROFILE.SEND:
            return {
                ...state,
                isLoading: true
            };

        case PROFILE.LOADED:
            return {
                ...state,
                profile: payload.profile,
                options: payload.options,
                notifications: payload.notifications,
                isLoading: false
            };

        case PROFILE.FAIL:
            return {
                ...state,
                isLoading: false,
                message: payload,
            };

        case PROFILE.USER_LOADED:
            return {
                ...state,
                userProfile: payload.userProfile
            };

        case ANNOUNCER.FAVORITE:
            let {userProfile} = state;
            const {id,isFavorite,quantity} = payload;

            if (userProfile.id === id) {
              userProfile.isFavorite = isFavorite;
              userProfile.favoritesCount = quantity;
            }

            return {
                  ...state,
                  userProfile
              };

        case PROFILE.USER_FAIL:
            return {
                ...state,
                userProfile: {}
            };

        // case PROFILE.CREATE_SEND:
        case PROFILE.CREATE_LOADED:
            return {
                ...state,
                profile: payload.profile,
                isLoading: false
            };

        case PROFILE.CREATE_FAIL:
            return {
                ...state,
                message: payload,
            };

        default:
            return state;
    }
};
