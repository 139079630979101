import API from '../api/api';
import ApiList from '../api/apiList';
import { StorageService } from "./StorageService";
import { STORAGE_KEY } from '../constants/LocalStorageKeys';
import moment from 'moment';

export const AuthService = {
    login: (form) => API.post(ApiList.AUTH.LOGIN, form),
    restore: (form) => API.post(ApiList.AUTH.RESTORE, form),
    refresh: (form) => API.post(ApiList.AUTH.REFRESH, form),
    register: (form) => API.post(ApiList.AUTH.REGISTER, form),

    getToken: () => {
        const token = StorageService.get(STORAGE_KEY.TOKEN) || false;
        const tokenExpired = StorageService.get(STORAGE_KEY.TOKEN_EXPIRED) || null;
        if (token) {
            if (!AuthService.isExpired(tokenExpired)) {
                return token
            }
        }

        return '';
    },
    getTokenRefresh: () => {
        const tokenRefresh = StorageService.get(STORAGE_KEY.TOKEN_REFRESH) || null;
        const refreshExpired = StorageService.get(STORAGE_KEY.TOKEN_REFRESH_EXPIRED) || null;
        if (tokenRefresh) {
            if (!AuthService.isExpired(refreshExpired)) {
                return tokenRefresh
            }
        }

        return '';
    },
    setToken: ({ token, expired, refresh_token, refresh_expired_at, other = false }) => {
        StorageService.set(STORAGE_KEY.TOKEN, token);
        StorageService.set(STORAGE_KEY.TOKEN_EXPIRED, expired);
        StorageService.set(STORAGE_KEY.OTHER, other);
        if (!other) {
            StorageService.set( STORAGE_KEY.TOKEN_REFRESH, refresh_token );
            StorageService.set( STORAGE_KEY.TOKEN_REFRESH_EXPIRED, refresh_expired_at );
        } else {
            StorageService.remove( STORAGE_KEY.TOKEN_REFRESH, refresh_token );
            StorageService.remove( STORAGE_KEY.TOKEN_REFRESH_EXPIRED, refresh_expired_at );
        }
        API.setToken(token);
    },
    removeToken: () => {
        if (StorageService.get(STORAGE_KEY.OTHER)) {
            StorageService.clear();
        } else {
            StorageService.remove(STORAGE_KEY.TOKEN);
            StorageService.remove(STORAGE_KEY.TOKEN_EXPIRED);
            StorageService.remove(STORAGE_KEY.TOKEN_REFRESH);
            StorageService.remove(STORAGE_KEY.TOKEN_REFRESH_EXPIRED);
        }

        API.setToken('');
    },
    isExpired: (expired) => {
        return moment(expired).local().isSameOrBefore(moment());
    },
};
