import API from "../api/api";
import ApiList from "../api/apiList";
import { ParseToRequest } from "../helpers/Tools";
import axios from "axios";

const CancelToken = axios.CancelToken;

let sourceChat;
let sourceMessage;
let sourceFile;
let sourceBill;

export const ChatListService = {
    get: (form, id = '') => {
        sourceChat = CancelToken.source();
        return API.get( `${ ApiList.CHAT.OPPONENT }${ id ? `/${ id }` : '' }${ ParseToRequest({ ...form, timestamp: (new Date()).getTime() } ) }`, {
            cancelToken: sourceChat.token
        } );
    },
    delete: (id) => API.delete( `${ ApiList.CHAT.OPPONENT }/${ id }` ),
    create: (form) => API.post( ApiList.CHAT.OPPONENT, form ),
    update: (form, id) => API.put( `${ ApiList.CHAT.OPPONENT }/${ id }`, form ),
    cancelLast: () => sourceChat && sourceChat.cancel( 'Operation canceled by the user.' )
};

export const MessageService = {
    get: (form, id) => {
        sourceMessage = CancelToken.source();
        return API.get( `${ ApiList.CHAT.MESSAGES }/${ id }${ ParseToRequest( { ...form, timestamp: (new Date()).getTime() }  ) }`, {
            cancelToken: sourceMessage.token
        } );
    },
    delete: (id) => API.delete( `${ ApiList.CHAT.MESSAGES }/${ id }` ),
    create: (form, id, config = null) => API.post( `${ ApiList.CHAT.MESSAGES }/${ id }`, form, config),
    update: (form, id) => API.put( `${ ApiList.CHAT.MESSAGES }/${ id }`, form ),
    cancelLast: () => sourceMessage && sourceMessage.cancel( 'Operation canceled by the user.' )
};

const baseUrlBill = (id) => `${ ApiList.CHAT.OPPONENT }/${ id }${ ApiList.CHAT.BILL }`;
export const BillService = {
    get: (form, id) => {
        sourceBill = CancelToken.source();
        return API.get( `${ baseUrlBill(id) }${ ParseToRequest( form ) }`, {
            cancelToken: sourceBill.token
        } );
    },
    delete: (id) => API.delete( `${ baseUrlBill(id) }` ),
    create: (form, id, config = null) => API.post( `${ baseUrlBill(id) }`, form, config),
    update: (form, id) => API.put( `${ baseUrlBill(id) }`, form ),
    cancelLast: () => sourceBill && sourceBill.cancel( 'Operation canceled by the user.' )
};
const baseUrlRead = (id) => `${ ApiList.CHAT.OPPONENT }/${ id }${ ApiList.CHAT.READ }`;
export const ReadService = {
    read: (id, messageIds) => API.post( `${ baseUrlRead(id) }`, { messageIds })
};

export const FileService = {
    get: (form, id = '') => {
        sourceFile = CancelToken.source();
        return API.get( `${ ApiList.CHAT.MESSAGES }${ id ? `/${ id }` : '' }${ ParseToRequest( form ) }`, {
            cancelToken: sourceFile.token
        } );
    },
    delete: (id) => API.delete( `${ ApiList.FILE.FILE }/${ id }` ),
    create: (form) => API.post( ApiList.FILE.FILE, form,  { headers: { 'Content-Type': 'multipart/form-data' },}),
    cancelLast: () => sourceFile && sourceFile.cancel( 'Operation canceled by the user.' ),
    share: (messageId) => API.post(`${ApiList.FILE.SHARE}/${messageId}/share`)
};
