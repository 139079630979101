import API from '../api/api';
import ApiList from '../api/apiList';
import { ParseToRequest } from '../helpers/Tools';
import axios from 'axios';

const CancelToken = axios.CancelToken;

let source;

export const LangService = {
    get: (id = '') => {
        source = CancelToken.source();
        return API.get(`${ApiList.SETTING.LANG}${id ? `/${id}` : ParseToRequest({is_full: 1})}`, {
            cancelToken: source.token
        });
    },
    delete: (id) => API.delete(`${ApiList.SETTING.LANG}/${id}`),
    create: (form) => API.post(ApiList.SETTING.LANG, form),
    update: (form, id) => API.put(`${ApiList.SETTING.LANG}/${id}`, form),
    cancelLast: () => source && source.cancel('Operation canceled by the user.')
};
