const config = {
    production: {
        apiURL: '/api/v1'
    },
    development: {
        URL: process.env.REACT_APP_URL,
        apiURL: process.env.REACT_APP_API_URL
    },
    none: {
        apiURL: '/api/v1'
    }
};

module.exports = config[process.env.NODE_ENV || 'development'];
