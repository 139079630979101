import { BillService, ChatListService, FileService, MessageService, ReadService } from '../../services';

const CHAT = {
    LOAD: 'golosmarket/chat/chat/LOADED',
    LOADING: 'golosmarket/chat/chat/LOADING',
    READ_LOAD: 'golosmarket/chat/read/LOADED',
    READ_LOADING: 'golosmarket/chat/read/LOADING',
};

const MESSAGE = {
    LOAD: 'golosmarket/chat/message/LOADED',
    LOADING: 'golosmarket/chat/message/LOADING',
    SENDING: 'golosmarket/chat/message/SENDING',
    UPDATE: 'golosmarket/chat/message/UPDATE',
    SEND: 'golosmarket/chat/message/SEND',
    CONCAT: 'golosmarket/chat/message/CONCAT',
    MESSAGE_EDIT:'golosmarket/chat/message/MESSAGE_EDIT',
    MESSAGE_EDIT_COMPLETE:'golosmarket/chat/message/MESSAGE_EDIT_COMPLETE',
    DELETE_SEND: 'golosmarket/chat/message/DELETE_SEND',
    DELETE_COMPLETE: 'golosmarket/chat/message/DELETE_COMPLETE',
    BILL_CREATING: 'golosmarket/chat/message/BILL_CREATING',
    BILL_CREATE: 'golosmarket/chat/message/BILL_CREATE',
    BILL_UPDATING: 'golosmarket/chat/message/BILL_UPDATING',
    BILL_UPDATE: 'golosmarket/chat/message/BILL_UPDATE',
    BILL_CANCELING: 'golosmarket/chat/message/BILL_CANCELING',
    BILL_CANCEL: 'golosmarket/chat/message/BILL_CANCEL',
    FILE_SENDING: 'golosmarket/chat/message/FILE_SENDING',
    FILE_SEND: 'golosmarket/chat/message/FILE_SEND',
    FAVORITE: 'golosmarket/chat/message/FAVORITE',
    SHARE_FILE_SEND: 'golosmarket/chat/message/SHARE_FILE_SEND',
    SHARE_FILE_LOAD: 'golosmarket/chat/message/SHARE_FILE_LOAD',
};

export function requestServiceChat(filter) {
    return ChatListService
        .get(filter)
        .then(response => ({
            items: response.data,
            pagination: {
                page: +response.headers['x-pagination-current-page'],
                quantityPages: +response.headers['x-pagination-page-count'],
                perPage: +response.headers['x-pagination-per-page'],
                totalCount: +response.headers['x-pagination-total-count'],
                quantity: response.data.length
            }
        }));
}

export function requestServiceReadChat(id, messageIds) {
    console.log('requestServiceReadChat', id, messageIds);
    return ReadService.read(id, messageIds);
}

export function requestChats(filter) {
    return dispatch => {
        dispatch({
            type: CHAT.LOADING,
            payload: { inProgress: true }
        });
        return requestServiceChat(filter)
            .then(data => {
                dispatch({
                    type: CHAT.LOAD,
                    payload: {
                        items: data.items,
                        pagination: data.pagination,
                        inProgress: false
                    }
                });
            });
    }
}

export function requestReadChats(idOpponent, messageIds) {
    return dispatch => {
        dispatch({
            type: CHAT.READ_LOADING,
            payload: { inProgress: true }
        });
        return requestServiceReadChat(idOpponent, messageIds)
            .then(({ data }) => {
                dispatch({
                    type: CHAT.READ_LOAD,
                    payload: {
                        idOpponent,
                        unread: data.quantity,
                        inProgress: false
                    }
                });
            });
    }
}

export function requestServiceShareFile(messageId) {
    return FileService.share(messageId);
}

export function requestMessages(id, filter = {}, isPagination = false, isUpdate = false ) {
    return dispatch => {
        dispatch({
            type: MESSAGE.LOADING,
            payload: {
                id,
                inProgress: true
            }
        });
        return MessageService
            .get(filter, id)
            .then(({ data: { items = [], opponent, bill, unread }, headers }) => ({
                items,
                opponent,
                bill,
                unread,
                pagination: {
                    page: +headers['x-pagination-current-page'],
                    quantityPages: +headers['x-pagination-page-count'],
                    perPage: +headers['x-pagination-per-page'],
                    totalCount: +headers['x-pagination-total-count'],
                    quantity: items.length,
                },
                inProgress: false
            }))
            .then(payload => {
                dispatch({
                    type: isPagination
                        ? MESSAGE.CONCAT
                        : isUpdate
                            ? MESSAGE.UPDATE
                            : MESSAGE.LOAD,
                    payload
                })
            });
    }
}

export function requestShareFile(messageId) {
    return dispatch => {
        dispatch({
            type: MESSAGE.SHARE_FILE_SEND,
            payload: { inProgress: true }
        });
        return requestServiceShareFile(messageId)
            .then(({ data: { items = [], opponent, bill }, headers }) => ({
                items,
                opponent,
                bill,
                pagination: {
                    page: +headers['x-pagination-current-page'],
                    quantityPages: +headers['x-pagination-page-count'],
                    perPage: +headers['x-pagination-per-page'],
                    totalCount: +headers['x-pagination-total-count'],
                    quantity: items.length,
                },
                inProgress: false,
                loadNewAnnouncer: false,
            }))
            .then((payload) => {
                dispatch({
                    type: MESSAGE.SHARE_FILE_LOAD,
                    payload
                });
            });
    }
}

export function requestDeleteMessage(messageId) {
    return dispatch => {
        dispatch({
            type: MESSAGE.DELETE_SEND,
            payload: {
                id: messageId,
                inProgress: true,
            }
        });
        return MessageService
            .delete(messageId)
            .then(() => dispatch({
                type: MESSAGE.DELETE_COMPLETE,
                payload: { id: messageId }
            }));
    }
}

export function editMessage(activeAnnouncerId, messageId, messageText) {
    return dispatch => {
        dispatch({
            type: MESSAGE.MESSAGE_EDIT,
        });
        const form = { text: messageText, message_id: messageId, edit:true };
        return MessageService
            .create(form, activeAnnouncerId)
            .then(() => dispatch({
                type: MESSAGE.MESSAGE_EDIT_COMPLETE,
            }));
    }
}

export function sendMessage(id, text, replyMessage) {
    return dispatch => {
        dispatch({
            type: MESSAGE.SENDING,
        });

        const form = { text };

        if (replyMessage && replyMessage.id) {
            form.parent_id = replyMessage.id;
        }

        return MessageService
            .create(form, id)
            .then(({ data: { items = [], opponent, bill, unread }, headers }) => ({
                items, // .reverse(),
                opponent,
                bill,
                unread,
                pagination: {
                    page: +headers['x-pagination-current-page'],
                    quantityPages: +headers['x-pagination-page-count'],
                    perPage: +headers['x-pagination-per-page'],
                    totalCount: +headers['x-pagination-total-count'],
                    quantity: items.length
                },
                inProgress: false,
                loadNewAnnouncer: false,
            }))
            .then(payload => {
                dispatch({ type: MESSAGE.SEND, payload });
            });
    }
}

export function createBill(id, sum, card_number) {
    return dispatch => {
        dispatch({
            type: MESSAGE.BILL_CREATING,
        });

        return BillService
            .create({ sum, card_number }, id)
            .then(response => response.data)
            .then((bill) => {
                dispatch({
                    type: MESSAGE.BILL_CREATE,
                    payload: {
                        bill
                    }
                });
            });
    }
}

export function updateBill(id, is_payed = 0, status) {
    console.log(id, is_payed, status)
    return dispatch => {
        dispatch({
            type: MESSAGE.BILL_CREATING,
        });

        return BillService
            .update({ is_payed, status }, id)
            .then(response => response.data)
            .then((bill) => {
                dispatch({
                    type: MESSAGE.BILL_CREATE,
                    payload: {
                        bill
                    }
                });
            });
    }
}

export function cancelBill(id) {
    return dispatch => {
        dispatch({
            type: MESSAGE.BILL_CREATING,
        });

        return BillService
            .delete(id)
            .then(response => response.data)
            .then(() => {
                dispatch({
                    type: MESSAGE.BILL_CREATE,
                    payload: { bill: null }
                });
            });
    }
}

export function sendFiles(id, files) {
    if (!files.length) {
        return () => Promise.resolve();
    }
    return dispatch => {
        dispatch({
            type: MESSAGE.FILE_SENDING,
        });

        const formData = new FormData();
        formData.enctype = 'multipart/form-data';

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            formData.append('files[' + i + ']', file);
        }

        return MessageService
            .create(formData, id)
            .then(({ data: { items = [], opponent, bill, unread }, headers }) => {
                return dispatch({
                    type: MESSAGE.FILE_SEND,
                    payload: { items, unread, opponent, bill }
                });
            });
    }
}

const initialState = {
    chat: {
        items: [],
        pagination: {},
        inProgress: false
    },
    message: {
        items: [],
        pagination: {},
        opponent: {},
        unread: 0,
        bill: null,
        inProgress: false,
        fileIsLoading: false,
    },
};

export const chat = (state = initialState, { type, payload }) => {
    switch (type) {
        case CHAT.LOAD:
            const chat = { ...payload };

            if (state.message.opponent
                && state.message.opponent.id
                && !chat.items.find(({ opponentId }) => opponentId === state.message.opponent.id )
            ) {
                chat.items.unshift({ ...state.message.opponent, opponentId: state.message.opponent.id });
            }

            return {
                ...state,
                chat,
            };
        case CHAT.LOADING:
            return {
                ...state,
                chat: {
                    ...state.chat,
                    inProgress: payload.inProgress
                }
            };

        case MESSAGE.LOADING:
            return {
                ...state,
                message: {
                    ...state.message,
                    items: state.message.opponent.id !== payload.id ? [] : [ ...state.message.items ],
                    // pagination: {
                    //     ...state.message.pagination,
                    //     page: 0
                    // },
                    inProgress: payload.inProgress,
                    bill: payload.clearMessages ? null : state.message.bill,
                }
            };

        case MESSAGE.LOAD:
            const lastMessageId = (state.message.items || []).findIndex(({ id }) => id === payload.items[payload.items.length - 1].id);
            const messages = [ ...payload.items, ...state.message.items.slice(lastMessageId !== -1 ? lastMessageId + 1 : 0) ];

            if (!state.chat.items.find(({ opponentId }) => opponentId === payload.opponent.id )) {
                state.chat.items.unshift({ ...payload.opponent, opponentId: payload.opponent.id });
            }

            return {
                ...state,
                message: {
                    ...state.message,
                    items: messages,
                    opponent: payload.opponent,
                    bill: payload.bill,
                    unread: parseInt(payload.unread),
                    inProgress: payload.inProgress,
                    pagination: payload.pagination,
                    loadNewAnnouncer: payload.loadNewAnnouncer,
                }
            };
        case MESSAGE.UPDATE:
            if (!payload.items) return state;

            const findFirstMessageIndex = (index) => {
                const firstMessageId = payload.items[index].id;
                const stateMessageIndex = state.message.items.findIndex(({ id }) => id === firstMessageId);

                if (stateMessageIndex === -1) {
                    return findFirstMessageIndex(index++);
                }

                return stateMessageIndex;
            };

            const findLastMessageIndex = (index) => {
                const lastMessageId = payload.items[index].id;
                const stateMessageIndex = state.message.items.findIndex(({ id }) => id === lastMessageId);

                if (stateMessageIndex === -1) {
                    return findLastMessageIndex(index--);
                }

                return stateMessageIndex;
            };

            const firstIndex = findFirstMessageIndex(0);
            const lastIndex = findLastMessageIndex(payload.items.length -1);

            if (firstIndex === -1 || lastIndex === -1) return  state;

            const firstMessages = state.message.items.slice(0, firstIndex);
            const lastMessages = state.message.items.slice(lastIndex);

            const result = [ ...lastMessages, ...payload.items, ...firstMessages ];

            return {
                ...state,
                message: {
                    ...state.message,
                    items: result,
                    opponent: payload.opponent,
                    bill: payload.bill,
                    unread: parseInt(payload.unread),
                    inProgress: payload.inProgress,
                    pagination: payload.pagination,
                    loadNewAnnouncer: payload.loadNewAnnouncer,
                }
            };
        case MESSAGE.CONCAT:
            return {
                ...state,
                message: {
                    ...state.message,
                    items: [ ...state.message.items, ...payload.items ],
                    opponent: payload.opponent,
                    bill: payload.bill,
                    unread: parseInt(payload.unread),
                    inProgress: payload.inProgress,
                    pagination: payload.pagination,
                    loadNewAnnouncer: payload.loadNewAnnouncer,
                }
            };

        case MESSAGE.SEND:
        case MESSAGE.FILE_SEND:
            return {
                ...state,
                message: {
                    ...state.message,
                    items: payload.items,
                    opponent: payload.opponent,
                    bill: payload.bill,
                    unread: parseInt(payload.unread),
                    fileIsLoading: false,
                },
            };
        case MESSAGE.FILE_SENDING:
            return  {
                ...state,
                message: {
                    ...state.message,
                    fileIsLoading: true,
                }
            }
        case MESSAGE.BILL_CREATE:
        case MESSAGE.BILL_UPDATE:
        case MESSAGE.BILL_CANCEL:
            return {
                ...state,
                message: {
                    ...state.message,
                    bill: payload.bill,
                }
            };

        case MESSAGE.FAVORITE:
            const { isFavorite } = payload;

            return {
                ...state,
                message: {
                    ...state.message,
                    opponent: {
                        ...state.message.opponent,
                        isFavorite
                    }
                }
            };

        case MESSAGE.SHARE_FILE_LOAD:
            return {
                ...state,
                message: {
                    ...state.message,
                    items: state.message.opponent.id !== payload.opponent ? [] : payload.items,
                    inProgress: payload.inProgress,
                    loadNewAnnouncer: payload.loadNewAnnouncer,
                    bill: payload.clearMessages ? null : state.message.bill,
                }
            }

        case MESSAGE.DELETE_COMPLETE:
            return {
                ...state,
                message: {
                    ...state.message,
                    items: state.message.items.filter(({ id }) => id !== payload.id)
                }
            }

        case CHAT.READ_LOAD:
            return {
                ...state,
                chat: {
                    ...state.chat,
                    items: state.chat.items.map(item => {
                        if (item.opponentId === payload.idOpponent) {
                            item.unread = parseInt(item.unread) - parseInt(payload.unread)
                        }
                        return item
                    })
                }
            }

        default:
            return state;
    }
};
