export const FORMS = {
    CITY: 'CityForm',
    PROFILE: 'ProfileForm',
    REGION: 'RegionForm',
    DISTRICT: 'DistrictForm',
    COUNTRY: 'CountryForm',
    LOGIN: 'loginForm',
    RESTORE: 'restoreForm',
    REFRESH: 'refreshForm',
    REGISTER_ANNOUNCER: 'registerAnnouncerForm',
    REGISTER_CLIENT: 'registerClientForm',
    WORK_TIME: 'workTimeForm',
    REVIEW: 'reviewForm',
    FEEDBACK: 'feedbackForm',
    REVIEW_SERVICE: 'reviewServiceForm',
    REVIEW_USER: 'reviewUserForm',
};
