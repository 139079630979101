import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import moment from 'moment';

import store from './data/redux/store';
import 'react-toastify/dist/ReactToastify.css';
import './components/Announcer/announcer.css';
import './components/Favorites/favorites.css';
import './components/Chat/chat.css';
import "./components/Header/header.css";
import './components/HistoryBill/history.css';

moment.locale( 'ru' );

let localComponents = {
  announcer: true,
  favorite: true,
  filter: true,
  profile: true,
  'profile-info' : true,
  history: true,
  chat: true,
  header: true,
  player: true,
}

const recommendedElement = document.getElementById( 'recommended' );
if ( recommendedElement ) {
    import ("./components/RecommendedList/RecommendedList")
        .then( ({ default: RecommendedList }) => {
            return ReactDOM.render( <Provider store={ store }><RecommendedList /></Provider>, recommendedElement );
        } )
}

const announcerElement = document.getElementById( 'announcer' );
if ( announcerElement ) {
    import ("./components/Announcer/Announcer")
        .then( ({ default: Announcer }) => {
            return ReactDOM.render( <Provider store={ store }><Announcer favoritePage={false}/></Provider>, announcerElement );
        } )
}

const collectionElement = document.getElementById( 'collection' );
if ( collectionElement ) {
    import ("./components/Collection/Collection")
        .then( ({ default: Collection }) => {
            return ReactDOM.render( <Provider store={ store }><Collection /></Provider>, collectionElement );
        } )
}

const favoriteElement = document.getElementById( 'favorite' );
if ( favoriteElement ) {
    import ("./components/Announcer/Announcer")
        .then( ({ default: Announcer }) => {
            return ReactDOM.render( <Provider store={ store }><Announcer favoritePage={true}/></Provider>, favoriteElement );
        } )
}

const filterElement = document.getElementById( 'filter' );
if ( filterElement ) {
    import ("./components/Filter/Filter")
        .then( ({ default: Filter }) => {
            return ReactDOM.render( <Provider store={ store }><Filter favoritePage={favoriteElement !== null} /></Provider>, filterElement );
        } )
}

const profileElement = document.getElementById( 'profile' );
if ( profileElement ) {
    import ("./components/Profile/Profile")
        .then( ({ default: Profile }) => {
            return ReactDOM.render( <Provider store={ store }><Profile/></Provider>, profileElement );
        } )
}

const profileInfoElement = document.getElementById( 'profile-info' );
if ( profileInfoElement ) {
    import ("./components/Profile/ProfileInfo")
        .then( ({ default: ProfileInfo }) => {
            return ReactDOM.render( <Provider store={ store }><ProfileInfo orders={profileInfoElement.dataset.orders} payments={profileInfoElement.dataset.payments} isAnnouncer={profileInfoElement.dataset.announcer} online={profileInfoElement.dataset.online} profileId={profileInfoElement.dataset.user}/></Provider>, profileInfoElement );
        } )
}

const historyElement = document.getElementById( 'history' );
if ( historyElement ) {
    import ("./components/HistoryBill/HistoryBill")
        .then( ({ default: History }) => {
            return ReactDOM.render( <Provider store={ store }><History/></Provider>, historyElement );
        } )
}

const chatElement = document.getElementById( 'chat' );
if ( chatElement ) {
    import ("./components/Chat/Chat")
        .then( ({ default: Chat }) => {
            return ReactDOM.render( <Provider store={ store }><Chat/></Provider>, chatElement );
        } );

    ['.heading', '.menu-footer', '.quote', 'footer'].map( item => {
        const element = document.querySelector( item );
        if (element) {
            element.parentNode.removeChild( element );
        }
        return element;
    } );
}

const headerElement = document.getElementById( 'header' );
if ( headerElement ) {
    import ("./components/Header/Header")
        .then( ({ default: Header }) => {
            return ReactDOM.render( <Provider store={ store }><Header/></Provider>, headerElement );
        } );
}
const playerElement = document.getElementById( 'player' );
if ( playerElement ) {
    import ("./components/Player/Player")
        .then( ({ default: Player }) => {
            return ReactDOM.render( <Provider store={ store }><Player/></Provider>, playerElement );
        } );
}

document.addEventListener('DOMContentLoaded', function () {
  let features = document.querySelectorAll('.feature-holder div h2');

  if (features.length) {
    for (let i = 0; i < features.length; i++) {
      const feature = features[i];

      feature.addEventListener('click', () => {
        feature.parentElement.classList.toggle('opened');

        for (let j = 0; j < features.length; j++) {
          if (j !== i) {
            features[j].parentElement.classList.remove('opened');
          }
        }
      });
    }
  }
});
