import API from '../api/api';
import ApiList from '../api/apiList';
import { ParseToRequest } from "../helpers/Tools";

export const ProfileService = {
    get: () => API.get(ApiList.USER.PROFILE),
    getFull: () => API.get(ApiList.USER.FULL),
    getPayment: () => API.get(ApiList.USER.PAYED),
    updatePayment: () => API.put(ApiList.USER.PAYED),
    getFullId: (id) => API.get(ApiList.USER.FULLID + id),
    update: form => API.put(ApiList.USER.FULL, form),
    scheduleUpdate: form => API.put(ApiList.USER.SCHEDULE, form),
    removeFile: form => API.put(ApiList.USER.FILE, form),
    defaultSound: form => API.put(ApiList.USER.SOUND, form),
    read: form => API.post(ApiList.USER.READ, form),
    bill: form => API.get(`${ ApiList.USER.BILL }${ ParseToRequest(form) }`),
    delete: (id) => API.delete(ApiList.USER.DELETE + id),
};
