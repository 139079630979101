import {stopSubmit} from "redux-form";
import isObject from "lodash/isObject";

import {AuthService} from '../../services';
import {FORMS} from "../../constants";

const LOGIN = {
    SEND: 'golosmarket/auth/login/SEND',
    LOADED: 'golosmarket/auth/login/LOADED',
    FAIL: 'golosmarket/auth/login/FAIL',
};
const RESTORE = {
    SEND: 'golosmarket/auth/restore/SEND',
    LOADED: 'golosmarket/auth/restore/LOADED',
    FAIL: 'golosmarket/auth/restore/FAIL',
};
const REFRESH = {
    SEND: 'golosmarket/auth/refresh/SEND',
    LOADED: 'golosmarket/auth/refresh/LOADED',
    FAIL: 'golosmarket/auth/refresh/FAIL',
};
const REGISTER_CLIENT = {
    INIT: 'golosmarket/auth/register_client/INIT',
    SEND: 'golosmarket/auth/register_client/SEND',
    LOADED: 'golosmarket/auth/register_client/LOADED',
    FAIL: 'golosmarket/auth/register_client/FAIL',
    FAIL_BUSY: 'golosmarket/auth/register_client/FAIL_BUSY',
};
const REGISTER_ANNOUNCER = {
    INIT: 'golosmarket/auth/register_announcer/INIT',
    SEND: 'golosmarket/auth/register_announcer/SEND',
    LOADED: 'golosmarket/auth/register_announcer/LOADED',
    FAIL: 'golosmarket/auth/register_announcer/FAIL',
    FAIL_BUSY: 'golosmarket/auth/register_announcer/FAIL_BUSY',
};
const LOGOUT = 'AUTH_LOGOUT';

const convertErrors = (errors) => {
    const error = {};

    isObject(errors) && Object.keys(errors).forEach(key => {
        const {field, message} = errors[key]
        error[field] = message;
    });

    return error;
};

export const setToken = (token) => ({
    type: LOGIN.LOADED,
    token
});

export function Login(params) {
    return dispatch => {
        dispatch({
            type: LOGIN.SEND
        });
        return AuthService
            .login(params)
            .then(response => response.data)
            .then(({token, expired, refresh_token, refresh_expired_at}) => {
                AuthService.setToken({token, expired, refresh_token, refresh_expired_at, other: params.other});
                return dispatch(setToken(token));
            })
            .catch(data => {
                const fields = data ? convertErrors(data.data) : {};
                const action = stopSubmit(FORMS.LOGIN, fields);
                dispatch(action);

                return dispatch({
                    type: LOGIN.FAIL,
                    payload: {...data.data}
                });
            });
    };
}

export function Restore(params) {
    return dispatch => {
        dispatch({
            type: RESTORE.SEND
        });
        return AuthService
            .restore(params)
            .then(response => response.data)
            .then(() => {
                dispatch({
                    type: RESTORE.LOADED
                });
            })
            .catch(data => {
                const fields = convertErrors(data.data);
                const action = stopSubmit(FORMS.RESTORE, fields);
                dispatch(action);

                return dispatch({
                    type: RESTORE.FAIL,
                    payload: {...data.data}
                });
            });
    };
}

export function RegisterAnnouncer(params) {
    params.type = 'announcer';
    return dispatch => {
        dispatch({
            type: REGISTER_ANNOUNCER.SEND
        });
        return AuthService
            .register(params)
            .then(response => response.data)
            .then(({token, expired, refresh_token, refresh_expired_at}) => {
                AuthService.setToken({token, expired, refresh_token, refresh_expired_at, other: params.other});
                return dispatch(setToken(token));
            })
            .then(() => {
                dispatch({
                    type: REGISTER_ANNOUNCER.LOADED
                });
            })
            .catch(data => {
                const fields = convertErrors(data.data);
                const action = stopSubmit(FORMS.REGISTER_ANNOUNCER, fields);
                dispatch(action);

                if (data.status == 406) {
                    return dispatch({
                        type: REGISTER_ANNOUNCER.FAIL_BUSY,
                        payload: {...data.data}
                    });
                } else {
                  return dispatch({
                    type: REGISTER_ANNOUNCER.FAIL,
                    payload: {...data.data}
                  });
                }
            });
    };
}

export function RegisterClient(params) {
    params.type = 'client';
    return dispatch => {
        dispatch({
            type: REGISTER_CLIENT.SEND
        });
        return AuthService
            .register(params)
            .then(response => response.data)
            .then(() => {
                dispatch({
                    type: REGISTER_CLIENT.LOADED
                });
            })
            .catch(data => {
                const fields = convertErrors(data.data);
                const action = stopSubmit(FORMS.REGISTER_CLIENT, fields);
                dispatch(action);

              if (data.status == 406) {
                  return dispatch({
                      type: REGISTER_CLIENT.FAIL_BUSY,
                      payload: {...data.data}
                  });
              } else {
                return dispatch({
                  type: REGISTER_CLIENT.FAIL,
                  payload: {...data.data}
                });
              }
            });
    };
}

export function RegisterClientInit() {
    return dispatch => dispatch({
        type: REGISTER_CLIENT.INIT
    });
}

export function RegisterAnnouncerInit() {
    return dispatch => dispatch({
        type: REGISTER_ANNOUNCER.INIT
    });
}

export function Refresh({dispatch, ...params}) {
    return AuthService
        .refresh(params)
        .then(response => response.data)
        .then(({token, expired, refresh_token, refresh_expired_at}) => {
            AuthService.setToken({token, expired, refresh_token, refresh_expired_at, other: params.other});
            return Promise.all([
                dispatch(setToken(token)),
                dispatch({
                    type: REFRESH.SEND
                })
            ]);
        })
        .catch(data => {
            const fields = convertErrors(data.data);
            const action = stopSubmit(FORMS.REFRESH, fields);
            dispatch(action);

            return dispatch({
                type: REFRESH.FAIL,
                payload: {...data.data}
            });
        });
}

export function logout() {
    AuthService.removeToken();
    return {type: LOGOUT};
}

const initialState = {isLoading: false, token: null};

export const auth = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN.SEND:
            return {
                ...state.auth,
                isLoading: true
            };

        case LOGIN.LOADED:
            return {
                token: action.token,
                isLoading: false
            };

        case LOGIN.FAIL:
            return {
                ...state.auth,
                isLoading: false,
                message: action.payload,
            };

        case LOGOUT:
            return {
                ...initialState
            };

        default:
            return state;
    }
};

export const refresh = (state = initialState, action) => {
    switch (action.type) {
        case REFRESH.SEND:
            return {
                ...state,
                isLoading: true
            };

        case REFRESH.LOADED:
            return {
                ...state,
                token: action.token,
                isLoading: false
            };

        case REFRESH.FAIL:
            return {
                ...state,
                isLoading: false,
                message: action.payload,
            };

        default:
            return state;
    }
};

export const restore = (state = {isLoading: false}, action) => {
    switch (action.type) {
        case RESTORE.SEND:
            return {
                ...state,
                isLoading: true
            };

        case RESTORE.LOADED:
            return {
                ...state,
                isLoading: false
            };

        case RESTORE.FAIL:
            return {
                ...state,
                isLoading: false,
                message: action.payload,
            };

        default:
            return state;
    }
};

export const STATUS = {
    INIT: 'INIT',
    SEND: 'SEND',
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
    FAIL_BUSY: 'FAIL_BUSY',
};

export const registerClient = (state = {status: STATUS.INIT}, action) => {
    switch (action.type) {
        case REGISTER_CLIENT.INIT:
            return {
                ...state,
                status: STATUS.INIT
            };

        case REGISTER_CLIENT.SEND:
            return {
                ...state,
                status: STATUS.SEND
            };

        case REGISTER_CLIENT.LOADED:
            return {
                ...state,
                status: STATUS.SUCCESS
            };

        case REGISTER_CLIENT.FAIL:
            return {
                ...state,
                status: STATUS.FAIL
            };

        case REGISTER_CLIENT.FAIL_BUSY:
            return {
                ...state,
                status: STATUS.FAIL_BUSY
            };

        default:
            return state;
    }
};

export const registerAnnouncer = (state = {status: STATUS.INIT}, action) => {
    switch (action.type) {
        case REGISTER_ANNOUNCER.INIT:
            return {
                ...state,
                status: STATUS.INIT
            };

        case REGISTER_ANNOUNCER.SEND:
            return {
                ...state,
                status: STATUS.SEND
            };

        case REGISTER_ANNOUNCER.LOADED:
            return {
                ...state,
                status: STATUS.SUCCESS
            };

        case REGISTER_ANNOUNCER.FAIL:
            return {
                ...state,
                status: STATUS.FAIL
            };

        case REGISTER_ANNOUNCER.FAIL_BUSY:
            return {
                ...state,
                status: STATUS.FAIL_BUSY
            };

        default:
            return state;
    }
};
