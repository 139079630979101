import axios from 'axios';
import { get as _get } from 'lodash';

import store from "../redux/store";
import config from '../config/';
import { logout } from "../redux/auth";

const options = {
    baseURL: config.apiURL,
    headers: {
        'Content-Type': 'application/json',
        'withCredentials': true
    }
};

const httpService = axios.create(options);

httpService.setToken = (token) => {
    httpService.defaults.headers.Authorization = `Bearer ${ token }`;
};

httpService.removeToken = () => {
    httpService.defaults.headers.Authorization = '';
};

httpService.interceptors.response.use(
    response => response,
    error => {
        const response = error.response;

        if (response && response.status === 401) {
            console.error('unauthorized, logging out ...');
            // toast.error("Ошибка авторизации!");

            store.dispatch(logout);
            return Promise.reject(error);
        }

        if (!axios.isCancel(error) && _get(response, 'data', []).length) {
            // response.data.forEach(msg => toast.error(msg.message));
        }

        return Promise.reject(response);
    }
);

export default httpService;
