import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';

import {
  announcer,
  app,
  setIsMobile,
  auth,
  chat,
  feedback,
  filter,
  profile,
  registerAnnouncer,
  registerClient,
  review,
  sidebar,
  header,
  favLocalList,
  user
} from './';

const reducers = () => combineReducers({
  sidebar,
  header,
  auth,
  user,
  app,
  setIsMobile,
  chat,
  filter,
  announcer,
  review,
  feedback,
  profile,
  registerClient,
  favLocalList,
  registerAnnouncer,
  form: formReducer
});

export default reducers;
