import {FilterService} from "../../services";

const FILTER = {
  LOADING: 'golosmarket/FILTER/SEND',
  SET_COST: 'golosmarket/FILTER/SET_COST',
  LOAD: 'golosmarket/FILTER/LOADED',
  FAIL: 'golosmarket/FILTER/FAIL'
};

export function requestFilters() {
  return dispatch => {
    dispatch({
      type: FILTER.LOADING,
      payload: {inProgress: true}
    });
    return FilterService
      .get()
      .then(response => response.data)
      .then(data => {
        dispatch({
          type: FILTER.LOAD,
          payload: {
            ...data.data,
            isLoading: false
          }
        });
      });
  }
}

export function setCostFilter(cost) {
  return dispatch => {
    dispatch({
      type: FILTER.SET_COST,
      payload: {currentCost: cost}
    });
  }
}

const getArray = (name) => window.filtersList && window.filtersList.hasOwnProperty(name) ? window.filtersList[name] || [] : [];

const initialState = {
  isLoading: false,
  message: '',
  optLang: getArray('lang'),
  optAge: getArray('age'),
  optPresents: getArray('presentations'),
  buttonsCost: getArray('cost'),
  currentCost: null,
  sortFields: [
    {title: 'По цене (сначала дешевле)', field: 'cost', asc: true},
    {title: 'По цене (сначала дороже)', field: 'cost', asc: false},
    // {title: 'по рейтингу', field: 'rating'},
    {title: 'По оценкам (сначала больше)', field: 'quantity', asc: false},
    {title: 'По оценкам (сначала меньше)', field: 'quantity', asc: true}
    //{title: 'По добавлениям в избранное (сначала больше)', field: 'favorites', asc: false},
    //{title: 'По добавлениям в избранное (сначала меньше)', field: 'favorites', asc: true}
  ],
  buttonsGender: [
    {
      title: 'Мужской',
      value: 'man'
    },
    {
      title: 'Женский',
      value: 'woman'
    }
  ],
  addFilters: [
    {
      name: 'online',
      value: false,
      title: 'Только онлайн'
    },
    {
      name: 'favorite',
      value: false,
      title: 'Избранное'
    },
    {
      name: 'presentation',
      value: '',
      title: 'Содержание демо'
    },
    {
      name: 'demo',
      value: false,
      title: 'Демо по Задаче'
    },
    {
      name: 'costBy',
      value: '',
      title: 'Демо по Задаче'
    }
  ]
};

export const filter = (state = initialState, action) => {
  switch (action.type) {
    case FILTER.LOADING:
      const {filters = [], sort = {}} = action.payload;
      return {
        ...state,
        isLoading: true,
        filters,
        sort
      };

    case FILTER.SET_COST:
      return {
        ...state,
        currentCost: action.payload.currentCost
      };

    case FILTER.LOADED:
      const {
        optStart = initialState.optStart,
        optStop = initialState.optStop,
        optLang = initialState.optLang,
        optAge = initialState.optAge
      } = action.payload;

      return {
        ...state,
        isLoading: false,
        optStart,
        optStop,
        optLang,
        optAge
      };

    case FILTER.FAIL:
      return {
        ...state,
        isLoading: false,
        message: action.payload
      };

    default:
      return state;
  }
};
