import { useState, useCallback } from "react";
import { getQueryStringValue, setQueryStringValue } from "./queryString";

const useQueryString = (initialValue) => {
    let queryString = getQueryStringValue();

    if (Object.keys(queryString).length === 0) {
        queryString = initialValue;
    }
    const [value, setValue] = useState(queryString);
    const onSetValue = useCallback(
        newValue => {
            setValue(newValue);
            setQueryStringValue(newValue);
        },
        []
    );

    return [value, onSetValue];
}

export default useQueryString;
