const HEADER = {
  SHOW_LOGIN_FORM: 'golosmarket/header/showLoginForm',
  SHOW_CONFIRMATION_FORM: 'golosmarket/header/showConfirmationForm',
  SHOW_FEEDBACK_FORM: 'golosmarket/header/showFeedbackForm',
  SHOW_MENUREGISTER: 'golosmarket/header/showMenuRegister',
  SHOW_RESTORE_FORM: 'golosmarket/header/showRestoreForm',
  SHOW_REGISTERCLIENT_FORM: 'golosmarket/header/showRegisterClientForm',
  SHOW_REGISTERANNOUNCER_FORM: 'golosmarket/header/showRegisterAnnouncerForm',
  SHOW_NEWANNOUNCER_POPUP: 'golosmarket/header/showNewAnnouncerPopUp',
  SHOW_WORK_TIME: 'golosmarket/header/showWorkTime',
  SHOW_WORK_TIME_POPUP: 'golosmarket/header/showWorkTimePopup',
};

export const setShowLoginForm = (open) => ({open, type: HEADER.SHOW_LOGIN_FORM});
export const setShowConfirmationForm = (open) => ({open, type: HEADER.SHOW_CONFIRMATION_FORM});
export const setShowFeedbackForm = (open) => ({open, type: HEADER.SHOW_FEEDBACK_FORM});
export const setShowMenuRegister = (open) => ({open, type: HEADER.SHOW_MENUREGISTER});
export const setShowRestoreForm = (open) => ({open, type: HEADER.SHOW_RESTORE_FORM});
export const setShowRegisterClientForm = (open) => ({open, type: HEADER.SHOW_REGISTERCLIENT_FORM});
export const setShowRegisterAnnouncerForm = (open) => ({open, type: HEADER.SHOW_REGISTERANNOUNCER_FORM});
export const setShowNewAnnouncerPopUp = (open) => ({open, type: HEADER.SHOW_NEWANNOUNCER_POPUP});
export const setShowWorkTime = (open) => ({open, type: HEADER.SHOW_WORK_TIME});
export const setShowWorkTimePopUp =  (open) => ({open, type: HEADER.SHOW_WORK_TIME_POPUP});

const initialState = {
  showLoginForm: false,
  showConfirmationForm: false,
  showFeedbackForm: false,
  showMenuRegister: false,
  showRestoreForm: false,
  showRegisterClientForm: false,
  showRegisterAnnouncerForm: false,
  showNewAnnouncerPopUp: false,
  showWorkTime: false,
  showWorkTimePopUp: false,
};

export const header = (state = initialState, action) => {
  switch (action.type) {
    case HEADER.SHOW_LOGIN_FORM:
      return {...state, showLoginForm: action.open};
    case HEADER.SHOW_CONFIRMATION_FORM:
      return {...state, showConfirmationForm: action.open};
    case HEADER.SHOW_FEEDBACK_FORM:
      return {...state, showFeedbackForm: action.open};
    case HEADER.SHOW_MENUREGISTER:
      return {...state, showMenuRegister: action.open};
    case HEADER.SHOW_RESTORE_FORM:
      return {...state, showRestoreForm: action.open};
    case HEADER.SHOW_REGISTERCLIENT_FORM:
      return {...state, showRegisterClientForm: action.open};
    case HEADER.SHOW_REGISTERANNOUNCER_FORM:
      return {...state, showRegisterAnnouncerForm: action.open};
    case HEADER.SHOW_NEWANNOUNCER_POPUP:
      return {...state, showNewAnnouncerPopUp: action.open}
    case HEADER.SHOW_WORK_TIME:
      return {...state, showWorkTime: action.open}
    case HEADER.SHOW_WORK_TIME_POPUP:
      return {...state, showWorkTimePopUp: action.open}

    default:
      return state;
  }
};
