import _ from 'lodash';

import { ParseToRequest } from '../helpers/Tools';

const List = {
    AUTH: {
        LOGIN: '/auth/login',
        RESTORE: '/auth/restore',
        REFRESH: '/auth/refresh',
        REGISTER: '/auth/register',
    },
    CHAT: {
        OPPONENT: '/chat',
        MESSAGES: '/chat',
        BILL: '/bill',
        READ: '/read',
    },
    FAVORITE: {
        FAVORITE: '/favorite',
    },
    FEEDBACK: {
        FEEDBACK: '/feedback',
    },
    FILE: {
        FILE: '/file',
        SHARE: '/chat'
    },
    ANNOUNCER: {
        ANNOUNCER: '/announcer',
        REVIEW: '/review',
        REVIEW_SERVICE: '/review/service',
        REVIEW_USER: '/review/user',
        FILTER: '/filter',
    },

    COLLECTION: {
        collection: '/collection',
        collectionId: '/collection/'
    },

    USER: {
        PROFILE: '/user/profile',
        FULL: '/user/full',
        FULLID: '/user/full?id=',
        SCHEDULE: '/user/schedule',
        FILE: '/user/file',
        SOUND: '/user/sound',
        READ: '/notify',
        BILL: '/user/bill',
        DELETE: '/user/',
        PAYED: '/user/payed'
    },
    SETTING: {
        LANG: '/lang',
    }
};

export const createURLGenerator = (serviceName) => {
    if (!List.hasOwnProperty(serviceName)) {
        return console.error(`Имя сервиса ${ serviceName } не найдено в списке`, 'apiList.js');
    }

    return property => {
        if (_.isObject(List[serviceName]) && !List[serviceName].hasOwnProperty(property)) {
            return console.error(`Свойство ${ property } не найдено в списке`, 'apiList.js');
        }

        let url = '';

        if (_.isObject(List[serviceName])) {
            const isFoundPrpp = List[serviceName].hasOwnProperty(property);

            if (!isFoundPrpp) return console.error(`Свойство ${ property } не найдено в списке`, 'apiList.js');

            url += List[serviceName][property];
        }

        if (_.isString(List[serviceName])) {
            url += List[serviceName];
        }

        return (...args) => {
            let form = null;
            let id = null;

            args.forEach(arg => {
                if (_.isObject(arg)) form = arg;
                if (_.isString(arg)) id = arg;
            });

            let result = url;

            if (id) result += `/${ id }`;
            if (form) result += ParseToRequest(form);

            return result;
        };
    };
};

export default List;
