import qs from "query-string";

const setQueryStringWithoutPageReload = qsValue => {
    const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        qsValue;
    window.history.pushState({ path: newurl }, "", newurl);
};

let parseResult = {
    key: '',
    result: {}
}
export const getQueryStringValue = (queryString = window.location.search) => {
    if (parseResult.key !== queryString) {
        const parse = qs.parse(queryString);
        for (const prop in parse) {
            if (parse[prop] === 'true') {
                parse[prop] = true;
            } else if (parse[prop] === 'false') {
                parse[prop] = false;
            } else if (!isNaN(parse[prop])) { //check on Number
                parse[prop] = parseInt(parse[prop])
            }
        }
        parseResult.key = queryString;
        parseResult.result = parse;
    }

    return parseResult.result;
};

export const setQueryStringValue = newValues => {
    const newQsValue = qs.stringify(newValues);
    setQueryStringWithoutPageReload(newQsValue ? `?${ newQsValue }` : '');
};
