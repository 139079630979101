import { AnnouncerService, FavoriteService, StorageService } from "../../services";
import { getQueryStringValue } from "../../queryParams";
import { favorites, setFavLocalList } from '../'

export const ANNOUNCER = {
  LOADING: 'golosmarket/announcer/SEND',
  LOAD: 'golosmarket/announcer/LOADED',
  FAIL: 'golosmarket/announcer/FAIL',
  PAGE: 'golosmarket/announcer/PAGE',
  FAVORITE: 'golosmarket/announcer/FAVORITE'
};

export function requestAnnouncers(filter = {}, page = 1, profile, mobile = false, favorite = false) {
  let filterNew = { ...filter, page, per_page: 10 };

  if (favorite) {
    if (profile === null) {
      if (favorites.length) {
        filterNew.id = favorites;
      }
    } else {
      filterNew.favorite = true;
    }
  }

  if (profile !== null) {
    filterNew.profile = profile;
  }

  return async dispatch => {
    filterNew.time = (new Date()).getTime();

    dispatch({
      type: ANNOUNCER.LOADING
    });

    if (favorite && favorites.length === 0 && profile === null) {
      dispatch({
        type: ANNOUNCER.LOAD,
        payload: {
          isFavoritePage: favorite,
          items: [],
          pagination: {
            page: 1,
            quantityPages: 1,
            perPage: filterNew.per_page,
            totalCount: 0
          },
          quantity: {
            man: 0,
            woman: 0,
            online: 0,
            favorites: 0,
            filters: 0
          },
          inProgress: false
        }
      });
    } else {
      try {
        const response = await AnnouncerService.get(filterNew);
        const data = {
          data: response.data,
          pagination: {
            page: +response.headers['x-pagination-current-page'],
            quantityPages: +response.headers['x-pagination-page-count'],
            perPage: +response.headers['x-pagination-per-page'],
            totalCount: +response.headers['x-pagination-total-count']
          }
        };

        dispatch({
          type: ANNOUNCER.LOAD,
          payload: {
            isFavoritePage: favorite,
            items: data.data.announcers,
            pagination: data.pagination,
            quantity: data.data.quantity,
            inProgress: false
          }
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
}

export function announcerChangeFavorite(id, isFavorite, quantity) {
  console.log('announcerChangeFavorite ann');
  return dispatch => dispatch({
    type: ANNOUNCER.FAVORITE,
    payload: { id, isFavorite, quantity }
  })
}

export function setPage(page = 1) {
  return dispatch => {
    dispatch({
      type: ANNOUNCER.PAGE,
      payload: { page }
    });
  }
}

const initialState = {
  isLoadingAnnouncer: true,
  isFavoritePage: false,
  announcers: [],
  message: '',
  quantity: {
    man: 0,
    woman: 0,
    online: 0,
    favorites: 0,
    filters: 0
  },
  pagination: {
    perPage: getQueryStringValue().per_page || 10,
    page: getQueryStringValue().page || 1,
    isNextPage: false
  }
};

export const announcer = (state = initialState, action) => {
  switch (action.type) {
    case ANNOUNCER.LOADING:
      return {
        ...state,
        isLoadingAnnouncer: true
      };

    case ANNOUNCER.LOAD:
      const { items = [], pagination, quantity = {}, isFavoritePage } = action.payload;
      quantity.filters = pagination.totalCount;

      return {
        ...initialState,
        quantity,
        announcers: items,
        isFavoritePage,
        isLoadingAnnouncer: false,
        pagination
      };

    case ANNOUNCER.FAVORITE:
      const { id, isFavorite } = action.payload;
      const setFavorite = item => {
        if (item.id === id) {
          item.isFavorite = isFavorite;
          item.quantityFavorite = action.payload.quantity;
        }

        return item;
      };

      console.log('setFavorite', id, isFavorite, action.payload.quantity);

      return {
        ...state,
        announcers: state.announcers.map(setFavorite)
      };

    case ANNOUNCER.FAIL:
      return {
        ...state,
        isLoadingAnnouncer: false,
        message: action.payload
      };

    case ANNOUNCER.PAGE:
      if (state.isLoadingAnnouncer) {
        return state;
      }

      return {
        ...state,
        pagination: { ...state.pagination, page: action.payload.page }
      };

    default:
      return state;
  }
};
