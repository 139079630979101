import {isString, isObject} from "underscore";

export const convertErrors = errors => {
    const error = {};
    if (isString(errors)) {
        return { _error: errors };
    }
    isObject(errors) && Object.keys(errors).map(key => {
        let message = errors[key][0];
        if (key.includes('translations')) {
            const keys = key.split('.');
            const fields = keys[0];
            const field = keys[2];
            const index = parseInt(keys[1]);
            if (!error[fields]) {
                error[fields] = [];
            }
            if (!error[fields][index]) {
                error[fields][index] = {};
            }
            message = message.replace(key, field);
            error[fields][index][field] = message;
        } else if (key === 'region_id') {
            message = message.replace('region id', 'Регион');
            error['region'] = message;
        } else {
            error[key] = message;
        }

        return key;
    });

    return error;
};

export const afterList = (service, key, params) => async dispatch => {
    const { page, perPage = 20, searchQuery = '', withTranslation = false } = params;
    dispatch({
        type: key.SEND
    });
    try {
        const response = await service({
            page,
            query: searchQuery,
            per_page: perPage,
            with_translation: withTranslation
        });

        const { data } = response;

        return dispatch({
            type: key.LOADED,
            payload: {
                items: data.data,
                pagination: {
                    perPage: +data.per_page,
                    page: +data.current_page,
                    isNextPage: !!data.next_page_url
                },
                inProgress: false
            }
        });
    } catch (data) {
        console.error('list-fail',data);

        return dispatch({
            type: key.FAIL,
            payload: data
        });
    }
};

// export const afterUpdate = (key = REGION['ADD'], formName = FORMS.REGION, callback, ...params) => async dispatch => {
//     try {
//         dispatch({
//             type: key.SEND
//         });
//         let { data } = await callback(...params);
//         await dispatch({
//             type: key.LOADED,
//             payload: {
//                 city: data,
//                 inProgress: false
//             }
//         });
//
//         return dispatch(stopSubmit(formName, {}));
//
//     } catch (data) {
//         const fields = convertErrors(data.data && (data.data.errors || data.data.message));
//
//         const action = stopSubmit(formName, fields);
//         dispatch(action);
//
//         return dispatch({
//             type: key.FAIL,
//             payload: { ...data.data }
//         });
//     }
// };
//
// export const afterRemove = (key = REGION['ADD'], callback, id) => async dispatch => {
//     try {
//         dispatch({
//             type: key.SEND
//         });
//         await callback(id);
//         return await dispatch({
//             type: key.LOADED,
//             payload: {
//                 id
//             }
//         });
//     } catch (data) {
//         return dispatch({
//             type: key.FAIL,
//             payload: { ...data.data }
//         });
//     }
// };

export const afterSendRequestSelect = (getService, params, type, typeFail) => async dispatch => {
    try {
        const data = (await getService(params)).data;
        return dispatch({
            type: type,
            payload: {
                selectItems: data.data,
                paginate: {
                    perPage: +data.per_page,
                    page: +data.current_page,
                    isNextPage: !!data.next_page_url
                },
                inProgress: false
            }
        });
    } catch (e) {
        return  dispatch({
            type: typeFail,
            payload: {
                selectItems: [],
                paginate: {
                    perPage: 0,
                    page: 0,
                    isNextPage: false
                },
                inProgress: false
            }
        });
    }
};
