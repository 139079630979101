import API from "../api/api";
import ApiList from "../api/apiList";
import {ParseToRequest} from "../helpers/Tools";
import axios from "axios";

const CancelToken = axios.CancelToken;
let source;

export const AnnouncerService = {
    get: (form, id = '') => {
        return API.get(`${ ApiList.ANNOUNCER.ANNOUNCER }${ id ? `/${ id }` : '' }${ ParseToRequest(form) }`);
    },
    delete: (id) => API.delete(`${ ApiList.ANNOUNCER.ANNOUNCER }/${ id }`),
    create: (form) => API.post(ApiList.ANNOUNCER.ANNOUNCER, form),
    update: (form, id) => API.put(`${ ApiList.ANNOUNCER.ANNOUNCER }/${ id }`, form),
    cancelLast: () => source && source.cancel('Operation canceled by the user.')
};

export const CollectionService = {
    get: () => {
        return API.get(ApiList.COLLECTION.collection);
    },
    getId: (id) => API.get(`${ ApiList.COLLECTION.collection }/${ id }`),
};

export const ReviewService = {
    get: (form) => {
        source = CancelToken.source();
        return API.get(`${ ApiList.ANNOUNCER.REVIEW }${ ParseToRequest(form) }`, {
            cancelToken: source.token
        });
    },
    createFeedback: (form) => API.post(ApiList.FEEDBACK.FEEDBACK, form),
    createReviewService: (form) => API.post(ApiList.ANNOUNCER.REVIEW_SERVICE, form),
    createReviewUser: (form) => API.post(ApiList.ANNOUNCER.REVIEW_USER, form),
    cancelLast: () => source && source.cancel('Operation canceled by the user.')
};

export const FilterService = {
    get: (form, id = '') => {
        source = CancelToken.source();
        return API.get(`${ ApiList.ANNOUNCER.FILTER }${ ParseToRequest(form) }`, {
            cancelToken: source.token
        });
    },
    cancelLast: () => source && source.cancel('Operation canceled by the user.')
};
